<template>
  <div class="position-relative"
    :style="`background-image:  url(${require('../assets/img/logo.jpg')}); background-size: cover; background-position: 50%;`">
    <span class="mask border-radius-lg opacity-1"></span>
    <v-app-bar :color="background" height="auto"
      class="py-2 px-0 border-radius-xl toolbar-content-padding-y-none" :class="navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
        " flat>
      <v-row>
        <v-col cols="12" sm="6" class="text-right">
          <div class="bg-primary opacity-0" style="height: 43px; width: 60px;"></div>
          <!--<v-btn disable elevation="0" :ripple="false" height="43" class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-0
            px-0
            rounded-sm
            opacity-10
          " :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }" v-if="$vuetify.breakpoint.mobile" color="transparent" @click="drawerClose">
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
            </div>
          </v-btn>-->
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  name: "app-bar-not-menu",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: Boolean,
    navbarFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      dropdown: [{
        icon: "email",
        title: "Check new messages",
      },
      {
        icon: "podcasts",
        title: "Manage podcast session",
      },
      {
        icon: "shopping_cart",
        title: "Payment successfully completed",
      },
      ],
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>