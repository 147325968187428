import axios from "./utils";

export const jobGetStatus = (userId) => {
  return axios.get(`/v1/jobs/status/`+ userId.toString());
};

export const jobStatusUpDate = (jobData, userId) => {
  return axios.put(`/v1/jobs/status/`+ userId.toString(), jobData);
};

export const jobDelete = (userId) => {
  return axios.delete(`/v1/jobs/`+ userId.toString());
};
