<template>
  <router-view></router-view>
</template>

<script>
window.onload = function () {
  /*
  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) { // 禁止多指觸控
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
    */

  let lastTouchEndTime = 0;
  document.addEventListener('touchend', function (event) {
    const now = new Date().getTime();
    if ((now - lastTouchEndTime) <= 300) {      // 偵測時間差是否小於 300ms
      event.preventDefault();
    }
    lastTouchEndTime = now;
  })

  /*
  let lastTouchEndTime = 0;
  document.addEventListener('touchend', (event) => {
    const now = new Date().getTime();
    if ((now - lastTouchEndTime) <= 300) {      // 偵測時間差是否小於 300ms
      event.preventDefault();
    }
    lastTouchEndTime = now;
  }, false);*/
}
export default {};
</script>
