/*
=========================================================
* Vuetify Membership Management System - v3.0.0
=========================================================

* Product Page:  https://www.creative-tim.com/product/vuetify-material-dashboard-pro
* Copyright 2022 Triple Two (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";

Vue.config.productionTip = false;

// i18n
//import VueI18n from "vue-i18n";
//Vue.use(VueI18n);
//const i18n = new VueI18n({
//    locale: 'zh', // set locale
//});

// Photoswipe Gallery
import Photoswipe from "vue-pswipe";
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

router.afterEach((to, from, next) => {
	window.scrollTo(0, -100);
});

// change other locale
//i18n.locale = 'zh-tw'

new Vue({
	//i18n,
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
    

