import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://so.zyt05.net',
  timeout: 60000, // 60 秒
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
    'Content-Type': 'application/json',
  },
});

// 可以在此處添加攔截器
instance.interceptors.request.use(
  config => {
    // 在請求發送之前做一些處理，例如添加 token
    // config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  error => Promise.reject(error)
);

instance.interceptors.response.use(
  response => response,
  error => {
    // 可在這裏處理響應錯誤
    return Promise.reject(error);
  }
);

export default instance;