<template>
  <div class="position-relative"
    :style="`background-image:  url(${require('../assets/img/logo.jpg')}); background-size: cover;`">
    <span class="mask border-radius-lg opacity-1"></span>
    <v-app-bar :color="background" height="auto" class="mt-6 py-2 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
      :class="{ 'border-bottom': !hasBg }" flat>
      <v-row class="py-1">
        <v-col cols="12" sm="6" class="d-flex">
          <div class="drawer-toggler pa-5 ms-6 cursor-pointer" :class="{ active: togglerActive }" @click="minifyDrawer"
            v-if="!$vuetify.breakpoint.mobile">
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line"></i>
              <i class="drawer-toggler-line"></i>
              <i class="drawer-toggler-line"></i>
            </div>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <v-btn elevation="0" :ripple="false" height="43" class="
            font-weight-600
            text-capitalize
            drawer-toggler
            py-3
            px-0
            rounded-sm
          " :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }" v-if="$vuetify.breakpoint.mobile" color="transparent" @click="drawerClose">
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
              <i class="drawer-toggler-line text-body"></i>
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
    toggleActive: String,
  },
  data() {
    return {
      drawer: false,
      togglerActive: false,
      dropdown: [{
        avatar: require("@/assets/img/team-2.jpg"),
        title: '<span class="font-weight-bold">New message</span> from Laur</h6>',
        time: "13 minutes ago",
      },
      {
        image: require("@/assets/img/small-logos/logo-spotify.svg"),
        title: '<span class="font-weight-bold">New album</span> by Travis Scott',
        time: "1 day",
      },
      {
        icon: require("@/assets/img/icons/card.svg"),
        title: "Payment successfully completed",
        time: "2 days",
      },
      ],
    };
  },
  methods: {
    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>